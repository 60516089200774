import styled, { keyframes } from "styled-components";

const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

export const ContactsContainer = styled.div`
  max-width: 1440px;
  padding: 0 25px;
  margin: 100px auto 0;
`;

export const Section = styled.section`
  margin-bottom: 90px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Info = styled.div`
  background: #ffffff;
  border-radius: 20px;
  width: 367px;
  padding: 25px 25px 25px 25px;
  margin-right: 20px;
  box-shadow: -3px 4px 15px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    margin-right: 10px;
  }
`;

export const Map = styled.div`
  position: relative;
  filter: brightness(90%);
  transition: all 0.5s ease-in-out;
  width: 745px;
  height: 460px;
  box-shadow: -3px 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  &:hover {
    filter: brightness(100%);
  }
`;

export const ContactsIcon = styled.img`
  height: 29px;
  width: 29px;
  margin-right: 10px;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    animation: ${shake} 0.6s;
  }
`;

export const ContactsLink = styled.a`
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: #4cbcd7;
    transition: 0.2s ease-in-out;
  }
`;

export const ContactsWrapperItem = styled.div`
  margin-bottom: 27px;
`;
