import React from "react";
import HeroSection from "../components/HeroSection";
import ContactsSection from "../components/ContactsSection";
import CallbackSection from "../components/CallbackSection";
import BenefitsSection from "../components/BenefitsSection";
import CarFleetSection from "../components/CarFleetSection";
import RentalThreeStepsSection from "../components/RentalThreeStepsSection";

const Home = () => {
  return (
    <>
      <HeroSection />
      <CarFleetSection />
      <BenefitsSection />
      <CallbackSection />
      <RentalThreeStepsSection />
      <ContactsSection />
    </>
  );
};

export default Home;
