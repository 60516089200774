import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const FooterSectionContainer = styled.div``;

export const Footer = styled.div`
  width: 100%;
  background: #3f3f3f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 10px;
`;

export const FooterTop = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const FooterBottom = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
    padding: 0 15px;
  }
`;

export const FooterBottomLink = styled(LinkS)`
  cursor: pointer;
  color: white;
  font-size: 16px;
  margin-left: 40px;
  border-bottom: 1px solid #fff;

  &:hover {
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid #4cbcd7;
  }
`;

export const FooterNavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
`;

export const FooterNavItem = styled.li`
  height: 80px;
  display: flex;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const FooterNavLinks = styled(LinkS)`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #4cbcd7;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 0rem;
  }
`;

export const FooterNavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterNavPhone = styled.a`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  margin-right: 20px;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #4cbcd7;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterNavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1440px;
`;
