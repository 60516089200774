import React, { useState } from "react";
import {
  Card,
  CardActionView,
  CardImage,
  CardPriceTitle,
  CardPriceValue,
  CardPriceView,
  CardTitle,
  List,
  ListItem,
  ListItemI,
  ListItemInfo,
  ListItemTitle,
  ListItemValue,
} from "./CarFleetSectionElements";
import { Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Slider from "@mui/material/Slider";
import "pure-react-carousel/dist/react-carousel.es.css";
import { mainData } from "../../data/mainData";
import tooltipIcon from "../../images/tooltip.svg";
import { useTranslation } from "react-i18next";

const CarCard = (props) => {
  const { t, i18n } = useTranslation();
  const { item, handleOpen } = props;
  const [dayCount, setDayCount] = useState(1);
  const [carPrice, setCarPrice] = useState(item.dailyPrice);
  const currentLanguageIsEN = i18n.language === 'en';

  const whatsappPreFillMessage = currentLanguageIsEN ? `${mainData.whatsapp}?text=Hi, I  wan to rent a ${item.model}, ${item.year} year, ${item.color} color` : `${mainData.whatsapp}?text=Привет, Я хочу арендовать ${item.model}, ${item.year} года, ${item.color} цвета`;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // 1023px and below

  const marks = [
    {
      value: 1,
      label: `${t("carFleetSection.marksLabel")}`,
    },
    {
      value: 7,
      label: `${t("carFleetSection.marksLabel")}`,
    },
    {
      value: 14,
      label: `${t("carFleetSection.marksLabel")}`,
    },
    {
      value: 24,
      label: `${t("carFleetSection.marksLabel")}`,
    },
    {
      value: 30,
      label: `${t("carFleetSection.marksLabel")}`,
    },
  ];

  const displayMarks = marks.map((mark) => ({
    value: mark.value,
    label: isSmallScreen ? `${mark.value} ${mark.label.slice(0, 1)}` : `${mark.value} ${mark.label}`,
  }));

  const valuetext = (value) => {
    return `${value} Days`;
  }

  const calculatePrice = (days) => {
    const basePrice = item.dailyPrice;
    let discountRate = 0;

    if (days < 7) {
      discountRate = 0;
    } else if (days >= 7 && days < 20) {
      discountRate = 0.1;
    } else if (days >= 20 && days < 30) {
      discountRate = 0.2;
    } else {
      discountRate = 0.4;
    }

    const discountedDailyRate = basePrice * (1 - discountRate);
    return Math.round(discountedDailyRate * days);
  };

  const updatePrice = (days) => {
    const totalPrice = calculatePrice(days);
    setCarPrice(totalPrice);
  };

  const handleDaysChange = (newDayCount) => {
    setDayCount(newDayCount);
    updatePrice(newDayCount);
  };

  return (
    <Card>
      <CardImage src={item.imageUrl} onClick={() => handleOpen(item.images)} />
      <CardTitle>{item.model}</CardTitle>
      <List>
        <ListItem>
          <ListItemTitle>{t("carFleetSection.year")}:</ListItemTitle>
          <ListItemI />
          <ListItemValue>{item.year}</ListItemValue>
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("carFleetSection.color")}:</ListItemTitle>
          <ListItemI />
          <ListItemValue>{item.color}</ListItemValue>
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("carFleetSection.seats")}:</ListItemTitle>
          <ListItemI />
          <ListItemValue>{item.seats}</ListItemValue>
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("carFleetSection.carType")}:</ListItemTitle>
          <ListItemI />
          <ListItemValue>{item.carType}</ListItemValue>
        </ListItem>
        <ListItem>
          <ListItemTitle>{t("carFleetSection.deposit")}:
            <Tooltip title={t("carFleetSection.depositDesc")}>
              <ListItemInfo src={tooltipIcon} alt="tooltipIcon" />
            </Tooltip>
          </ListItemTitle>
          <ListItemI />
          <ListItemValue>{item.deposit} AED</ListItemValue>
        </ListItem>
      </List>
      <CardPriceView>
        <CardPriceTitle>{t("carFleetSection.priceof")}:</CardPriceTitle>
        <CardPriceValue>{carPrice.toFixed(2)} AED</CardPriceValue>
      </CardPriceView>

      <CardActionView
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <div style={{ margin: 10 }}>
          <Slider
            onChange={(e, newValue) => {
              handleDaysChange(newValue)
            }}
            value={dayCount}
            max={30}
            min={1}
            aria-label="Custom marks"
            getAriaValueText={valuetext}
            defaultValue={1}
            step={1}
            valueLabelDisplay="auto"
            marks={displayMarks}
            onMouseDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          />
        </div>
        <Button
          style={{
            color: "white",
            backgroundColor: "#4cbcd7",
            borderRadius: 50,
            height: 52,
          }}
          fullWidth
          type="submit"
          variant="contained"
          href={whatsappPreFillMessage}
          target="_blank"
        >
          {t("carFleetSection.actionBtn")}
        </Button>
      </CardActionView>
    </Card>
  );
};

export default CarCard;
