import React from "react";
import { SectionTitle } from "../../components/main";
import styled from "styled-components";
import { mainData } from "../../data/mainData";

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const PrivacyPolicyRU = () => {
  return (
    <>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        Политика конфиденциальности (AMAIS/Объединенные Арабские Эмираты)
      </SectionTitle>
      <TextWrapper>
        <p>
          Добро пожаловать в Политику конфиденциальности <b>AMAIS</b>.
        </p>
        <p>
          В этой Политике конфиденциальности описано, как <b>AMAIS</b> собирает
          и обрабатывает ваши личные данные при посещении нашего сайта для
          регистрации учетной записи и использования наших услуг.
        </p>
        <p>
          Прочитайте эту политику конфиденциальности, чтобы узнать детальную
          информацию о том, как мы обрабатываем данные, а также ознакомьтесь с
          договором аренды автомобиля и его условиями.
        </p>
        <p>
          <b>КОНТРОЛЕР:</b>
        </p>
        <p>
          AMAIS Car Rental является контролером и ответственным за ваши личные
          данные.
        </p>
        <p>
          <b>ДАННЫЕ, КОТОРЫЕ МЫ СОБИРАЕМ О ВАС:</b>
        </p>
        <p>
          Согласно Федеральному Декрету-Закону № 45 от 2021 года о защите
          персональных данных (PDPL), персональные данные — это любые данные,
          относящиеся к идентифицированному или идентифицируемому физическому
          лицу:
        </p>
        <ul>
          <li>Имя.</li>
          <li>Идентификационный номер.</li>
          <li>Электронный идентификатор.</li>
          <li>Географическое местоположение.</li>
          <li>
            Физические, физиологические, экономические, культурные или
            социальные характеристики.
          </li>
          <li>
            Чувствительные данные, такие как семейное или этническое
            происхождение, политические или философские взгляды, религиозные
            убеждения, криминальное прошлое, биометрические данные и данные о
            здоровье.
          </li>
        </ul>
        <p>
          <b>КАК МЫ ИСПОЛЬЗУЕМ ВАШИ ЛИЧНЫЕ ДАННЫЕ:</b>
        </p>
        <p>
          AMAIS будет использовать и делиться вашими данными в следующих
          случаях:
        </p>
        <ul>
          <li>
            Для выполнения контракта или соблюдения юридических обязательств.
          </li>
          <li>
            Для регистрации вас как нового клиента:
            <ul>
              <li>Для доставки арендованного автомобиля.</li>
              <li>Для управления вашими отношениями с нами.</li>
              <li>Чтобы вы могли оставить отзыв или заполнить опрос.</li>
            </ul>
          </li>
          <li>Для улучшения нашего сайта.</li>
          <li>
            Мы можем делиться вашими данными с бизнес-партнерами для предложения
            услуг или акций, которые могут вас заинтересовать.
          </li>
        </ul>
        <p>
          <b>ССЫЛКИ НА ДРУГИЕ САЙТЫ</b>
        </p>
        <p>
          Наш сайт может содержать ссылки на другие сайты, чьи информационные
          практики могут отличаться от наших. Рекомендуем ознакомиться с их
          политикой конфиденциальности, так как мы не контролируем информацию,
          передаваемую или собираемую этими сайтами.
        </p>
        <p>
          <b>ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
        </p>
        <p>
          PDPL разрешает передачу данных за пределы ОАЭ в страны с "адекватным
          уровнем защиты". Это включает страны с законодательством о защите
          данных или подписавшие соответствующие соглашения.
        </p>
        <p>
          Для стран без адекватного уровня защиты закон предлагает следующие
          варианты передачи:
        </p>
        <ul>
          <li>
            По контракту или соглашению, соответствующему требованиям закона.
          </li>
          <li>
            С явного согласия субъекта данных, если это не противоречит
            общественным интересам и безопасности ОАЭ.
          </li>
          <li>
            Необходимость для выполнения контракта между контролером и субъектом
            данных, или с третьей стороной в интересах субъекта данных.
          </li>
          <li>
            Необходимость для выполнения обязательств или защиты прав в судебных
            органах.
          </li>
          <li>Необходимость для международного судебного сотрудничества.</li>
          <li>Необходимость для защиты общественных интересов.</li>
        </ul>
        <p>
          <b>ФАЙЛЫ COOKIE</b>
        </p>
        <p>
          Вы можете выбрать, принимать или отклонять файлы cookie. Большинство
          браузеров автоматически принимают файлы cookie. Если вы отключите или
          откажетесь от них, некоторые части сайта могут стать недоступными или
          не функционировать должным образом.
        </p>
        <p>
          <b>ВАШИ ЗАКОННЫЕ ПРАВА:</b>
        </p>
        <p>PDPL предоставляет вам несколько прав в отношении ваших данных:</p>
        <ul>
          <li>Получать или иметь доступ к своим данным.</li>
          <li>Запрашивать передачу своих данных.</li>
          <li>Запрашивать исправление или удаление своих данных.</li>
          <li>
            Запрашивать ограничение обработки своих данных в определенных
            случаях.
          </li>
          <li>
            Запрашивать прекращение обработки своих данных в определенных
            случаях.
          </li>
          <li>Возражать против обработки данных.</li>
          <li>
            Возражать против решений на основе автоматизированной обработки,
            которые имеют юридические последствия или сильно влияют на вас.
          </li>
          <li>Отозвать согласие на обработку данных в любое время.</li>
          <li>Подать жалобу в Управление по защите данных ОАЭ.</li>
        </ul>
        <p>
          Если вы хотите воспользоваться этими правами, свяжитесь с нашей
          службой поддержки по указанным контактным данным.
        </p>
        <p>
          <b>БЕЗОПАСНОСТЬ ДАННЫХ</b>
        </p>
        <p>
          Мы ценим ваше доверие и принимаем меры для предотвращения потери,
          использования или раскрытия ваших данных.
        </p>
        <p>
          <b>КОНФИДЕНЦИАЛЬНОСТЬ ДЕТЕЙ</b>
        </p>
        <p>
          Этот сайт не предназначен для детей, и мы не собираем данные о детях.
          Услуги AMAIS не предназначены для лиц младше 21 года.
        </p>
        <p>
          <b>ИЗМЕНЕНИЯ В ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ</b>
        </p>
        <p>
          Периодически проверяйте эту Политику конфиденциальности, чтобы быть в
          курсе изменений. Сообщайте нам, если ваши данные изменяются во время
          наших отношений.
        </p>
        <p>
          <b>ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</b>
        </p>
        <p>
          Если у вас есть вопросы о том, как мы обрабатываем ваши данные,
          свяжитесь с нами по адресу{" "}
          <a style={{ color: "black" }} href={`mailto:${mainData.email}`}>
            {mainData.email}
          </a>
          .
        </p>
      </TextWrapper>
    </>
  );
};

export default PrivacyPolicyRU;
