import styled from "styled-components";

export const CallbackContainer = styled.div`
  max-width: 1440px;
  padding: 0 25px;
  margin: 100px auto 0;
`;

export const CallbackTopContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const CallbackTopLeftContainer = styled.div`
  max-width: 66.666667%;
  flex-basis: 66.666667%;
`;

export const CallbackTopRightContainer = styled.div`
  display: block;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const CallbackTitle = styled.h1`
  font-size: 30px;
  margin-bottom: 27px;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export const CallbackSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export const CallbackIcon = styled.img``;

export const FormikContainer = styled.div`
  margin-top: 27px;
`;

export const CallbackSuccessModalContainer = styled.div``;

export const CallbackSuccessModalIcon = styled.img`
  margin-bottom: 24px;
`;

export const CallbackSuccessModalTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const CallbackSuccessModalDesc = styled.h1`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
`;

export const CallbackFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const CallbackFormItemContainer = styled.div`
  max-width: 25%;
  flex-basis: 25%;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
`;
