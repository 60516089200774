import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavPhone,
  NavLInkIcon,
  NavbarCallbackView,
  LogoIcon,
} from "./NavbarElements";
import LanguageSwitcher from "../LanguageSwitcher";
import whatsappIcon from "../../images/whatsapp.svg";
import telegramIcon from "../../images/telegram.svg";
import instagrammIcon from "../../images/instagramm.svg";
import facebookIcon from "../../images/facebook.svg";
import logoIcon from "../../images/logo.svg";
import { mainData } from "../../data/mainData";
import { Button } from "../ButtonElement";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const isSecondPage = location.pathname !== "/";

  const { t } = useTranslation();
  const menuData = t("menuData", { returnObjects: true }) ?? [];

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80, // Adjust offset as needed
        behavior: "smooth",
      });
    }
  };

  const handleClick = (id) => {
    if (isSecondPage) {
      history.push("/");
      setTimeout(() => {
        scrollToId(id);
      }, 300);
    } else {
      scrollToId(id);
    }
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={isSecondPage ? isSecondPage : scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <LogoIcon src={logoIcon} />
              AMAIS
            </NavLogo>
            <MobileIcon>
              <a target="_blank" href={mainData.whatsapp} rel="noopener noreferrer">
                <NavLInkIcon src={whatsappIcon} />
              </a>
              <a target="_blank" href={mainData.instagramm} rel="noopener noreferrer">
                <NavLInkIcon src={instagrammIcon} />
              </a>
              <a target="_blank" href={mainData.facebook} rel="noopener noreferrer">
                <NavLInkIcon src={facebookIcon} />
              </a>
              <a target="_blank" href={mainData.telegramm} rel="noopener noreferrer">
                <NavLInkIcon src={telegramIcon} />
              </a>
              <FaBars onClick={toggle} />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                {menuData?.map((item, index) => (
                  <NavLinks
                    onClick={() => handleClick(item.link)}
                    to={item.link}
                    key={index}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    {item.title}
                  </NavLinks>
                ))}
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavPhone href={`tel:${mainData.phoneNumber}`}>
                {mainData.phoneNumber}
              </NavPhone>
              <a target="_blank" href={mainData.whatsapp} rel="noopener noreferrer">
                <NavLInkIcon src={whatsappIcon} />
              </a>
              <a target="_blank" href={mainData.instagramm} rel="noopener noreferrer">
                <NavLInkIcon src={instagrammIcon} />
              </a>
              <a target="_blank" href={mainData.facebook} rel="noopener noreferrer">
                <NavLInkIcon src={facebookIcon} />
              </a>
              <a target="_blank" href={mainData.telegramm} rel="noopener noreferrer">
                <NavLInkIcon src={telegramIcon} />
              </a>
              <LanguageSwitcher />
              <NavbarCallbackView>
                <Button
                  to={"callback"}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-120}
                  dark="true"
                >
                  {t("callbackBtn")}
                </Button>
              </NavbarCallbackView>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
