import React from "react";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../config/i18n";
import { FormControl, MenuItem, Select } from "@mui/material";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        const lng = event.target.value;
        i18n.changeLanguage(lng);
    };

    return (
        <FormControl sx={{ margin: "10px" }}>
            <Select
                sx={{ backgroundColor: "#4cbcd7", borderRadius: "100px" }}
                size="small"
                labelId="language-select-label"
                id="language-select"
                value={i18n.language}
                onChange={changeLanguage}
                variant="outlined"
                displayEmpty
            >
                {supportedLanguages?.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                        {lang.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;
