import { ButtonBack, ButtonNext } from "pure-react-carousel";
import styled from "styled-components";

export const CarFleetContainer = styled.div`
  max-width: 1440px;
  padding: 0 25px;
  margin: 100px auto 0;
  overflow: hidden;
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 20px;
  min-height: 518px;
  margin-right: 20px;
  box-shadow: -3px 4px 15px rgba(0, 0, 0, 0.2);
`;

export const CardImage = styled.img`
  width: 100%;
  height: 290px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const CardTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 10px;
`;

export const CardActionView = styled.div`
  padding: 0px 20px 20px 20px;
`;

export const CardPriceView = styled.div`
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const CardPriceTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const CardPriceValue = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

export const List = styled.ul`
  padding: 0px 15px;
  list-style: none;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const ListItemTitle = styled.span` 
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ListItemInfo = styled.img` 
  margin-left: 5px;
  cursor: pointer;
`;

export const ListItemI = styled.i`
  border-bottom: 1px dashed #4cbcd7;
  flex-grow: 1;
  transform: translateY(-3px);
`;

export const ListItemValue = styled.span``;

export const DotGroupWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CarouselActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const CustomButtonBack = styled(ButtonBack)`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
`;

export const CustomButtonNext = styled(ButtonNext)`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
`;
