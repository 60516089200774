import React from "react";
import styled from "styled-components";
import { SectionTitle } from "../../components/main";

export const TermsConditionsContainer = styled.div`
  width: 100%;
`;

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const TermsConditionsEN = () => {
  return (
    <TermsConditionsContainer>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        General Terms and Conditions Regarding the Vehicle Rental Agreement
      </SectionTitle>
      <TextWrapper>
        <p>
          These General Terms are part of the vehicle rental agreement between the Client and AMAIS. The terms supplement the provisions of the Agreement.
        </p>
        <ol>
          <li>Definitions</li>
        </ol>
        <p>
          1.1 In these General Terms, the following words and expressions have the following meanings:
        </p>
        <p>
          Agreement means the agreement between the Client and AMAIS, including these Terms and the table on the AMAIS website, as amended.
        </p>
        <p>
          Table means the table of vehicle rental rates on the AMAIS website amaisrentacar.com.
        </p>
        <p>
          Additional drivers mean any additional drivers designated by the Client in the Agreement.
        </p>
        <p>
          Client means the customer who entered into the Agreement with AMAIS for vehicle rental.
        </p>
        <p>
          Driver's license means a valid driver's license issued by the relevant authorities, allowing the holder to operate a vehicle.
        </p>
        <p>
          Rental period means the duration of the rental specified in the Agreement and the anticipated/confirmed date of return of the vehicle. Any extension of the Rental Period is considered a new Rental Period.
        </p>
        <p>Per day for rental purposes means twenty-four (24) hours.</p>
        <p>
          Daily charge means the daily rental charge for the vehicle as specified in the Agreement.
        </p>
        <p>
          Allowed mileage means the daily allowed mileage specified in the Agreement.
        </p>
        <p>
          Restricted actions/use includes driving under the influence of alcohol, racing, off-road driving, driving through standing water, towing, etc.
        </p>
        <p>Terms mean these Standard terms of the Agreement.</p>
        <p>UAE means the United Arab Emirates.</p>
        <p>
          A valid driver's license must meet one of the following conditions:
        </p>
        <ol>
          <li>For UAE nationals - a driver's license issued in the UAE;</li>
          <li>
            For UAE residents - the license must be at least three years old or confirmed by an additional license from another country;
          </li>
          <li>
            For Chinese nationals, the license must be accompanied by a notarized translation into English;
          </li>
          <li>Driver's license issued in the following countries:</li>
        </ol>
        <p>(i) North America: Canada and the USA;</p>
        <p>
          (ii) East Asia and the Pacific: Australia, China, Hong Kong, Japan, New Zealand, Singapore, and South Korea;
        </p>
        <p>
          (iii) Europe: Albania, Austria, Belgium, Bulgaria, Cyprus, Denmark, Finland, Estonia, France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Montenegro, the Netherlands, Norway, Poland, Portugal, Romania, Serbia, Slovakia, Spain, Sweden, Switzerland, Turkey, and the United Kingdom;
        </p>
        <p>(iv) GCC: Bahrain, Kuwait, Oman, Saudi Arabia, and Qatar;</p>
        <p>(v) Africa: South Africa;</p>
        <ol>
          <li>
            Drivers from other countries may operate a vehicle only with an international driver's license and the original license from their country if they are not UAE residents.
          </li>
        </ol>
        <p>
          Additionally, the driver's license must be accompanied by a valid passport or a copy of the Emirates ID for UAE nationals and residents.
        </p>
        <p>
          Vehicle means the AMAIS vehicle rented by the Client under the terms of the Agreement.
        </p>
        <p>1.2 Unless the context requires otherwise:</p>
        <p>(a) singular includes plural and vice versa;</p>
        <p>(b) words in the masculine gender include the feminine gender;</p>
        <p>
          (c) words referring to individuals include companies, organizations, associations, or groups of individuals.
        </p>
        <p>
          1.3 The word “may” is interpreted as permission, and the word “shall” as an obligation.
        </p>
        <p>
          1.4 A reference to a document means a reference to the document in paper or electronic form.
        </p>
        <p>
          1.5 Unless the context requires otherwise, the singular includes the plural, and the plural includes the singular.
        </p>
        <p>
          1.6 Unless the context requires otherwise, a reference to one gender includes a reference to the other gender.
        </p>
        <p>
          1.7 An obligation of a person not to do anything includes an obligation not to allow others to do so.
        </p>
        <p>
          1.8 All dates and periods of time are determined according to the Gregorian calendar.
        </p>
        <p>
          1.9 Unless the context requires otherwise, “Emirati Dirham” or “AED” means the currency of the UAE.
        </p>
        <p>
          1.10 The words “include”, “includes”, and “including” are interpreted as “without limitation”.
        </p>
        <ol start="2">
          <li>Right to Rent, Required Documents, and Table</li>
        </ol>
        <p>
          2.1 The Client represents and warrants that he/she (and additional drivers) have a Valid Driver’s License and will maintain it during the Rental Period.
        </p>
        <p>
          2.2 The Client undertakes to notify AMAIS of the expiration or cancellation of the driver's license (his/her and additional drivers) and cease the use of the vehicle.
        </p>
        <p>
          2.3 The Client acknowledges that he/she has read and will comply with the schedule on the AMAIS website (amaisrentacar.com), which is part of the Agreement.
        </p>
        <p>
          2.4 An extension not agreed upon with AMAIS is considered a new rental period for the vehicle.
        </p>
        <p>
          NOTE: Rates may be changed or increased by AMAIS at its discretion.
        </p>
        <ol>
          <li>Delivery Policy</li>
        </ol>
        <p>
          3.1 The date and time of delivery of the vehicle must be agreed upon between the Client and AMAIS.
        </p>
        <p>
          3.2 The vehicle may be delivered outside of normal working days and hours for an additional fee. The Client must arrange with an AMAIS representative for the applicable fee.
        </p>
        <p>
          3.3 Upon taking possession of the vehicle, the Client agrees that the vehicle is in the condition specified in the Agreement and must maintain that condition until the vehicle is returned.
        </p>
        <ol start="3">
          <li>Rental Period, Return Policy, and Extension</li>
        </ol>
        <p>
          4.1 Daily charges for the vehicle are calculated based on 24 hours.
        </p>
        <p>
          4.2 An acceptable grace period is 2 hours on the date of return. For a delay of more than two hours, the Client pays a penalty of 1000 AED.
        </p>
        <p>
          4.3 In the case of shortening the Rental Period by one day or more, the agreed daily rate will be recalculated, and the remainder will be refunded to the Client less an early termination fee of 500 AED.
        </p>
        <p>
          4.4 Returns outside of normal working hours or at unagreed locations may be subject to additional fees. The Client must confirm such fees in advance with an AMAIS representative.
        </p>
        <p>
          4.5 The Client must send a written request to extend the Agreement before its expiration. The request is subject to acceptance or rejection by AMAIS.
        </p>
        <p>
          4.6 AMAIS may require the Client to replace the vehicle during business hours for maintenance or other purposes.
        </p>
        <p>
          4.7 AMAIS reserves the right to withdraw the vehicle at any time if the Client breaches the terms of the Agreement.
        </p>
        <p>
          4.8 If the Client fails to return the vehicle on the agreed date or does not pay the fees, AMAIS may:
        </p>
        <p>(a) report the vehicle to the police as stolen;</p>
        <p>(b) take legal action to recover damages;</p>
        <p>(c) recover the vehicle without notice to the Client;</p>
        <p>
          4.9 Inform AMAIS of fines for traffic violations or warnings during the rental period.
        </p>
        <p>
          4.10 The Client is responsible for any fines that may arise during the rental period.
        </p>
        <p>
          4.11 The Client agrees to compensate AMAIS for costs related to the collection of unpaid debts.
        </p>
        <ol start="5">
          <li>Vehicle Condition</li>
        </ol>
        <p>
          5.1 The Client agrees to keep the vehicle clean and in good working order.
        </p>
        <p>
          5.2 The Client agrees to notify AMAIS of any damage to the vehicle during the rental period.
        </p>
        <p>
          5.3 The Client shall not make any changes to the vehicle without the prior consent of AMAIS.
        </p>
        <ol start="6">
          <li>Insurance and Liability</li>
        </ol>
        <p>
          6.1 AMAIS provides insurance coverage for the vehicle as specified in the Agreement.
        </p>
        <p>
          6.2 The Client is responsible for damages not covered by insurance.
        </p>
        <p>
          6.3 The Client is responsible for all claims arising from the use of the vehicle.
        </p>
        <p>
          6.4 In the event of an accident, the Client must notify AMAIS immediately.
        </p>
        <p>
          6.5 The Client shall not accept any liability for damages caused by the vehicle.
        </p>
        <p>
          6.6 The Client agrees to indemnify AMAIS against any claims arising from the use of the vehicle.
        </p>
        <ol start="7">
          <li>Governing Law and Jurisdiction</li>
        </ol>
        <p>
          7.1 The Agreement shall be governed by the laws of the UAE.
        </p>
        <p>
          7.2 The parties agree that any disputes arising from the Agreement shall be subject to the exclusive jurisdiction of the UAE courts.
        </p>
        <ol start="8">
          <li>Contact Information</li>
        </ol>
        <p>
          For any questions or concerns regarding the terms of this Agreement, please contact AMAIS at [contact information].
        </p>
      </TextWrapper>
    </TermsConditionsContainer>
  );
};

export default TermsConditionsEN;
