import React from "react";
import styled from "styled-components";
import { SectionTitle } from "../../components/main";

export const TermsConditionsContainer = styled.div`
  width: 100%;
`;

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const TermsConditionsRU = () => {
  return (
    <TermsConditionsContainer>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        Общие условия и положения относительно Договора аренды транспортного
        средства
      </SectionTitle>
      <TextWrapper>
        <p>
          Эти Общие условия являются частью Договора аренды автомобиля между
          Заказчиком и AMAIS. Условия дополняют положения Договора.
        </p>
        <ol>
          <li>Определения</li>
        </ol>
        <p>
          1.1 В этих Общих условиях следующие слова и выражения имеют следующие
          значения:
        </p>
        <p>
          Договор означает договор между Заказчиком и AMAIS, включая эти Условия
          и таблицу на веб-сайте AMAIS, с изменениями.
        </p>
        <p>
          Таблица означает таблицу с тарифами на аренду автомобиля на сайте
          AMAIS amaisrentacar.com.
        </p>
        <p>
          Дополнительные водители означают любых дополнительных водителей,
          назначенных Заказчиком в Договоре.
        </p>
        <p>
          Заказчик означает клиента, который заключил Договор с AMAIS на аренду
          автомобиля.
        </p>
        <p>
          Водительское удостоверение означает действительное водительское
          удостоверение, выданное соответствующими органами, дающее право
          управлять автомобилем.
        </p>
        <p>
          Период аренды означает срок аренды, указанный в Договоре, и
          предполагаемую/подтвержденную дату возвращения автомобиля. Любое
          продление Периода аренды считается новым Периодом аренды.
        </p>
        <p>В сутки для целей аренды означает двадцать четыре (24) часа.</p>
        <p>
          Суточная плата означает суточную арендную плату за автомобиль,
          указанную в Договоре.
        </p>
        <p>
          Разрешенный пробег означает суточный разрешенный пробег, указанный в
          Договоре.
        </p>
        <p>
          Ограниченные действия/использование включает вождение под воздействием
          алкоголя, гонки, внедорожное вождение, вождение через стоячую воду,
          буксировка и т.д.
        </p>
        <p>Условия означают эти Стандартные условия Договора.</p>
        <p>ОАЭ означает Объединенные Арабские Эмираты.</p>
        <p>
          Действительное водительское удостоверение должно соответствовать
          одному из следующих условий:
        </p>
        <ol>
          <li>Для граждан ОАЭ - водительское удостоверение, выданное в ОАЭ;</li>
          <li>
            Для резидентов ОАЭ - удостоверение должно быть не менее трех лет или
            подтверждено дополнительным удостоверением из другой страны;
          </li>
          <li>
            Для граждан Китая удостоверение должно сопровождаться нотариально
            заверенным переводом на английский;
          </li>
          <li>Водительское удостоверение, выданное в следующих странах:</li>
        </ol>
        <p>(i) Северная Америка: Канада и США;</p>
        <p>
          (ii) Восточная Азия и Тихоокеанский регион: Австралия, Китай, Гонконг,
          Япония, Новая Зеландия, Сингапур и Южная Корея;
        </p>
        <p>
          (iii) Европа: Албания, Австрия, Бельгия, Болгария, Кипр, Дания,
          Финляндия, Эстония, Франция, Германия, Греция, Венгрия, Исландия,
          Ирландия, Италия, Латвия, Литва, Люксембург, Мальта, Черногория,
          Нидерланды, Норвегия, Польша, Португалия, Румыния, Сербия, Словакия,
          Испания, Швеция, Швейцария, Турция и Великобритания;
        </p>
        <p>(iv) GCC: Бахрейн, Кувейт, Оман, Саудовская Аравия и Катар;</p>
        <p>(v) Африка: Южная Африка;</p>
        <ol>
          <li>
            Водители из других стран могут управлять автомобилем только при
            наличии международного водительского удостоверения и оригинального
            удостоверения из своей страны, если они не являются резидентами ОАЭ.
          </li>
        </ol>
        <p>
          Кроме того, водительское удостоверение должно сопровождаться
          действительным паспортом или копией Эмирейтс ID для граждан и
          резидентов ОАЭ.
        </p>
        <p>
          Автомобиль означает автомобиль AMAIS, арендованный Заказчиком в
          соответствии с условиями Договора.
        </p>
        <p>1.2 Если контекст не требует иного:</p>
        <p>(a) единственное число включает множественное и наоборот;</p>
        <p>(b) слова в мужском роде включают женский род;</p>
        <p>
          (c) слова, относящиеся к физическим лицам, включают компании,
          организации, ассоциации или группы лиц.
        </p>
        <p>
          1.3 Слово «может» толкуется как разрешение, а слово «должен» как
          обязательство.
        </p>
        <p>
          1.4 Ссылка на документ означает ссылку на документ в бумажной или
          электронной форме.
        </p>
        <p>
          1.5 Если контекст не требует иного, единственное число включает
          множественное, а множественное включает единственное.
        </p>
        <p>
          1.6 Если контекст не требует иного, ссылка на один пол включает ссылку
          на другой пол.
        </p>
        <p>
          1.7 Обязательство человека не делать что-либо включает обязательство
          не позволять другим делать это.
        </p>
        <p>
          1.8 Все даты и периоды времени определяются по григорианскому
          календарю.
        </p>
        <p>
          1.9 Если контекст не требует иного, «Эмиратский дирхам» или «AED»
          означает валюту ОАЭ.
        </p>
        <p>
          1.10 Слова «включать», «включает» и «включая» толкуются как «без
          ограничений».
        </p>
        <ol start="2">
          <li>Право на аренду, необходимые документы и таблица</li>
        </ol>
        <p>
          2.1 Заказчик заявляет и гарантирует, что он/она (и дополнительные
          водители) имеют Действительное водительское удостоверение и будут
          поддерживать его в течение Периода аренды.
        </p>
        <p>
          2.2 Заказчик обязуется уведомить AMAIS о истечении срока или
          аннулировании водительского удостоверения (его/ее и дополнительных
          водителей) и прекратить использование автомобиля.
        </p>
        <p>
          2.3 Заказчик признает, что он прочитал и будет соблюдать расписание на
          сайте AMAIS (amaisrentacar.com), которое является частью договора.
        </p>
        <p>
          2.4 Продление, не согласованное с AMAIS, считается новым периодом
          аренды автомобиля.
        </p>
        <p>
          ПРИМЕЧАНИЕ: тарифы могут быть изменены или увеличены AMAIS по своему
          усмотрению.
        </p>
        <ol>
          <li>Политика доставки</li>
        </ol>
        <p>
          3.1 Дата и время доставки автомобиля должны быть согласованы между
          Заказчиком и AMAIS.
        </p>
        <p>
          3.2 Автомобиль может быть доставлен вне обычных рабочих дней и часов
          за дополнительную плату. Заказчик должен договориться с представителем
          AMAIS о соответствующей плате.
        </p>
        <p>
          3.3 При принятии автомобиля Заказчик соглашается, что автомобиль
          соответствует состоянию, указанному в Договоре, и должен поддерживать
          это состояние до возврата автомобиля.
        </p>
        <ol start="3">
          <li>Период аренды, политика возврата и продление</li>
        </ol>
        <p>
          4.1 Суточные сборы за автомобиль рассчитываются на основе 24 часов.
        </p>
        <p>
          4.2 Приемлемый льготный период составляет 2 часа на дату возврата. За
          задержку более двух часов Заказчик платит штраф в размере 1000 AED.
        </p>
        <p>
          4.3 В случае сокращения Периода аренды на один день или более
          согласованная суточная плата будет пересчитана, и остаток будет
          возвращен Заказчику за вычетом платы за досрочное расторжение в
          размере 500 AED.
        </p>
        <p>
          4.4 Возврат вне обычных рабочих часов или в не согласованных местах
          может быть подвержен дополнительным сборам. Заказчик должен заранее
          подтвердить такие сборы с представителем AMAIS.
        </p>
        <p>
          4.5 Заказчик должен направить письменный запрос на продление срока
          действия Договора до истечения его срока. Запрос подлежит принятию или
          отказу со стороны AMAIS.
        </p>
        <p>
          4.6 AMAIS может потребовать от Заказчика заменить автомобиль в рабочие
          часы рабочего дня для обслуживания или других целей.
        </p>
        <p>
          4.7 AMAIS оставляет за собой право отозвать автомобиль в любое время,
          если Заказчик нарушил условия Договора.
        </p>
        <p>
          4.8 Если Заказчик не возвращает автомобиль в согласованную дату или не
          оплачивает сборы, AMAIS может:
        </p>
        <p>(a) сообщить автомобиль в полицию как украденный;</p>
        <p>(b) предпринять законные действия для возмещения убытков;</p>
        <p>(c) вернуть автомобиль без уведомления Заказчика;</p>
        <p>
          4.9 Информировать AMAIS о штрафах за нарушение правил дорожного
          движения или предупреждениях в течение периода аренды.
        </p>
        <p>
          4.10 По запросу AMAIS предоставить точное местонахождение автомобиля.
        </p>
        <p>
          4.11 По запросу позволить представителю AMAIS осмотреть автомобиль.
        </p>
        <p>5 Требования к бронированию</p>
        <p>5.1 Минимальная предоплата за бронирование составляет 500 AED.</p>
        <p>
          5.2 Заказчик должен внести залог до получения автомобиля. Депозит
          удерживается AMAIS в течение 14 дней после окончания аренды для учета
          штрафов и дополнительных сборов.
        </p>
        <p>
          5.3 Если бронирование отменяется за 72 часа или более до начала
          аренды, предоплата возвращается Заказчику.
        </p>
        <p>
          5.4 Если бронирование отменяется менее чем за 72 часа до начала
          аренды, предоплата возвращается с вычетом 500 AED.
        </p>
        <p>
          5.5 Если бронирование откладывается или сокращается за 72 часа или
          менее до начала аренды, Заказчик платит дополнительный сбор в размере
          500 AED.
        </p>
        <p>
          5.6 Заказчик должен предоставить оригиналы документов при доставке
          автомобиля. Несоблюдение требований приведет к штрафу в размере 500
          AED.
        </p>
        <ol start="5">
          <li>Сборы и платежи</li>
        </ol>
        <p>
          6.1 Все сборы исключают НДС и другие налоги, которые оплачивает
          Заказчик.
        </p>
        <p>
          6.2 AMAIS взимает сбор в размере 150 AED за каждого дополнительного
          водителя. Заказчик и дополнительные водители остаются совместно
          ответственными по Договору.
        </p>
        <p>
          6.3 Любой дополнительный пробег будет оплачиваться согласно Договору.
        </p>
        <p>
          6.4 За каждый проезд через Salik будет взиматься плата в размере 5
          AED.
        </p>
        <p>
          6.5 Все штрафы в течение аренды оплачиваются Заказчиком. AMAIS может
          списать суммы с депозита Заказчика.
        </p>
        <p>
          6.6 Заказчик уполномочивает AMAIS списывать суммы с депозита для всех платежей по условиям Договора.
        </p>
      </TextWrapper>
    </TermsConditionsContainer>
  );
};

export default TermsConditionsRU;
