import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  LanguageSwitcherWrapper,
} from "./SidebarElements";
import { Button } from "../ButtonElement";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher"

const Sidebar = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const menuData = t("menuData", { returnObjects: true }) ?? [];

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {menuData?.map((item, index) => (
            <SidebarLink to={item.link} key={index} onClick={toggle}>
              {item.title}
            </SidebarLink>
          ))}
        </SidebarMenu>
        <SideBtnWrap>
          <Button
            onClick={toggle}
            to={"callback"}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-120}
            dark="true"
          >
            {t("callbackBtn")}
          </Button>
        </SideBtnWrap>
        <LanguageSwitcherWrapper>
          <LanguageSwitcher />
        </LanguageSwitcherWrapper>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
