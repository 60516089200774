import React, { useState } from "react";
import Video from "../../videos/video.mp4";
import videoThumbnail from "../../images/videoThumbnail.png";
import { Button } from "../ButtonElement";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ImageBg,
} from "./HeroElements";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

const HeroSection = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // 1023px and below


  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        {isSmallScreen ? <ImageBg src={videoThumbnail} alt="ImageBg" /> : <VideoBg autoPlay loop muted src={Video} type="video/mp4" />}
      </HeroBg>
      <HeroContent>
        <HeroH1>{t("heroSection.heroH1")}</HeroH1>
        <HeroP>{t("heroSection.heroP")}</HeroP>
        <HeroBtnWrapper>
          <Button
            to={"autoCatalog"}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-120}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            {t("heroSection.button")}  {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
