export const autoParkData = [
  {
    imageUrl: require("../images/auto/BMW430-2022/2.jpg"),
    model: "BMW 430i Grand coupe",
    year: 2022,
    color: "White",
    seats: 5,
    carType: "Petrol",
    dailyPrice: 500,
    monthlyPrice: 9000,
    deposit: 3000,
    images: [
      require("../images/auto/BMW430-2022/2.jpg"),
      require("../images/auto/BMW430-2022/1.jpg"),
      require("../images/auto/BMW430-2022/3.jpg"),
      require("../images/auto/BMW430-2022/4.jpg"),
      require("../images/auto/BMW430-2022/5.jpg"),
      require("../images/auto/BMW430-2022/6.jpg"),
      require("../images/auto/BMW430-2022/7.jpg"),
    ],
  },
  {
    imageUrl: require("../images/auto/BMW330-2020/2.jpg"),
    model: "BMW 330i",
    year: 2020,
    color: "White",
    seats: 5,
    carType: "Petrol",
    dailyPrice: 400,
    monthlyPrice: 7000,
    deposit: 2500,
    images: [
      require("../images/auto/BMW330-2020/2.jpg"),
      require("../images/auto/BMW330-2020/1.jpg"),
      require("../images/auto/BMW330-2020/3.jpg"),
      require("../images/auto/BMW330-2020/4.jpg"),
      require("../images/auto/BMW330-2020/5.jpg"),
      require("../images/auto/BMW330-2020/6.jpg"),
      require("../images/auto/BMW330-2020/7.jpg"),
    ],
  },
  {
    imageUrl: require("../images/auto/BMW330-2023/1.jpg"),
    model: "BMW 330i",
    year: 2023,
    color: "White",
    seats: 5,
    carType: "Petrol",
    dailyPrice: 400,
    monthlyPrice: 7000,
    deposit: 3000,
    images: [
      require("../images/auto/BMW330-2023/1.jpg"),
      require("../images/auto/BMW330-2023/2.jpg"),
      require("../images/auto/BMW330-2023/3.jpg"),
      require("../images/auto/BMW330-2023/4.jpg"),
      require("../images/auto/BMW330-2023/5.jpg"),
      require("../images/auto/BMW330-2023/6.jpg"),
      require("../images/auto/BMW330-2023/7.jpg"),
      require("../images/auto/BMW330-2023/8.jpg"),
      require("../images/auto/BMW330-2023/9.jpg"),
      require("../images/auto/BMW330-2023/10.jpg"),
    ],
  },
  {
    imageUrl: require("../images/auto/NissanPathfinder-2022/1.jpg"),
    model: "Nissan Pathfinder ",
    year: 2022,
    color: "Burgundy",
    seats: 7,
    carType: "Petrol",
    dailyPrice: 300,
    monthlyPrice: 7000,
    deposit: 2500,
    images: [
      require("../images/auto/NissanPathfinder-2022/1.jpg"),
      require("../images/auto/NissanPathfinder-2022/2.jpg"),
      require("../images/auto/NissanPathfinder-2022/3.jpg"),
      require("../images/auto/NissanPathfinder-2022/4.jpg"),
      require("../images/auto/NissanPathfinder-2022/5.jpg"),
      require("../images/auto/NissanPathfinder-2022/6.jpg"),
      require("../images/auto/NissanPathfinder-2022/7.jpg"),
      require("../images/auto/NissanPathfinder-2022/8.jpg"),
    ],
  },

  {
    imageUrl: require("../images/auto/BMW430-2021/1.jpg"),
    model: "BMW 430i Convertible",
    year: 2021,
    color: "White",
    seats: 5,
    carType: "Petrol",
    dailyPrice: 900,
    monthlyPrice: 17000,
    deposit: 3000,
    images: [
      require("../images/auto/BMW430-2021/1.jpg"),
      require("../images/auto/BMW430-2021/2.jpg"),
      require("../images/auto/BMW430-2021/3.jpg"),
      require("../images/auto/BMW430-2021/4.jpg"),
      require("../images/auto/BMW430-2021/5.jpg"),
      require("../images/auto/BMW430-2021/6.jpg"),
    ],
  },

  {
    imageUrl: require("../images/auto/BMW850-2022/1.jpg"),
    model: "BMW 850i Сonvertible",
    year: 2022,
    color: "Grey",
    seats: 5,
    carType: "Petrol",
    dailyPrice: 1500,
    monthlyPrice: 25000,
    deposit: 5000,
    images: [
      require("../images/auto/BMW850-2022/1.jpg"),
      require("../images/auto/BMW850-2022/2.jpg"),
      require("../images/auto/BMW850-2022/3.jpg"),
      require("../images/auto/BMW850-2022/4.jpg"),
      require("../images/auto/BMW850-2022/5.jpg"),
      require("../images/auto/BMW850-2022/6.jpg"),
      require("../images/auto/BMW850-2022/7.jpg"),
      require("../images/auto/BMW850-2022/8.jpg"),
      require("../images/auto/BMW850-2022/9.jpg"),
      require("../images/auto/BMW850-2022/10.jpg"),
    ],
  },
];
