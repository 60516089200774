import React from "react";
import { SectionTitle } from "../../components/main";
import styled from "styled-components";
import { mainData } from "../../data/mainData";

export const TextWrapper = styled.div`
  line-height: 135%;
  font-size: 16px;

  p {
    margin: 10px 0;
  }
`;

const PrivacyPolicyEN = () => {
  return (
    <>
      <SectionTitle style={{ padding: "0px 80px", textAlign: "center" }}>
        Privacy Policy (AMAIS/United Arab Emirates)
      </SectionTitle>
      <TextWrapper>
        <p>
          Welcome to the <b>AMAIS</b> Privacy Policy.
        </p>
        <p>
          This Privacy Policy describes how <b>AMAIS</b> collects and processes your personal data when visiting our website to register an account and use our services.
        </p>
        <p>
          Please read this Privacy Policy to learn more about how we handle your data and review the car rental agreement and its terms.
        </p>
        <p>
          <b>CONTROLLER:</b>
        </p>
        <p>
          AMAIS Car Rental is the controller and responsible for your personal data.
        </p>
        <p>
          <b>DATA WE COLLECT ABOUT YOU:</b>
        </p>
        <p>
          According to Federal Decree-Law No. 45 of 2021 on the Protection of Personal Data (PDPL), personal data is any data relating to an identified or identifiable natural person:
        </p>
        <ul>
          <li>Name.</li>
          <li>Identification number.</li>
          <li>Electronic identifier.</li>
          <li>Geographic location.</li>
          <li>
            Physical, physiological, economic, cultural, or social characteristics.
          </li>
          <li>
            Sensitive data, such as family or ethnic origin, political or philosophical beliefs, religious views, criminal history, biometric data, and health information.
          </li>
        </ul>
        <p>
          <b>HOW WE USE YOUR PERSONAL DATA:</b>
        </p>
        <p>
          AMAIS will use and share your data in the following situations:
        </p>
        <ul>
          <li>
            To fulfill a contract or comply with legal obligations.
          </li>
          <li>
            To register you as a new customer:
            <ul>
              <li>To deliver the rented car.</li>
              <li>To manage your relationship with us.</li>
              <li>To allow you to leave a review or complete a survey.</li>
            </ul>
          </li>
          <li>To improve our website.</li>
          <li>
            We may share your data with business partners to offer services or promotions that may interest you.
          </li>
        </ul>
        <p>
          <b>LINKS TO OTHER SITES</b>
        </p>
        <p>
          Our website may contain links to other sites whose information practices may differ from ours. We recommend reviewing their privacy policies, as we do not control the information transmitted or collected by these sites.
        </p>
        <p>
          <b>CROSS-BORDER TRANSFER OF PERSONAL DATA</b>
        </p>
        <p>
          PDPL permits the transfer of data outside the UAE to countries with an "adequate level of protection." This includes countries with data protection laws or those that have signed relevant agreements.
        </p>
        <p>
          For countries without an adequate level of protection, the law offers the following options for data transfer:
        </p>
        <ul>
          <li>
            Under a contract or agreement that meets legal requirements.
          </li>
          <li>
            With the explicit consent of the data subject, provided it does not contradict the public interest and security of the UAE.
          </li>
          <li>
            Necessary for the performance of a contract between the controller and the data subject, or with a third party in the interest of the data subject.
          </li>
          <li>
            Necessary for the performance of obligations or protection of rights in judicial bodies.
          </li>
          <li>
            Necessary for international judicial cooperation.
          </li>
          <li>Necessary to protect public interests.</li>
        </ul>
        <p>
          <b>COOKIES</b>
        </p>
        <p>
          You can choose to accept or decline cookies. Most browsers automatically accept cookies. If you disable or decline them, some parts of the website may become unavailable or not function properly.
        </p>
        <p>
          <b>YOUR LEGAL RIGHTS:</b>
        </p>
        <p>PDPL provides you with several rights regarding your data:</p>
        <ul>
          <li>Access or obtain your data.</li>
          <li>Request the transfer of your data.</li>
          <li>Request the correction or deletion of your data.</li>
          <li>
            Request the restriction of processing your data in certain cases.
          </li>
          <li>
            Request to stop the processing of your data in certain cases.
          </li>
          <li>Object to data processing.</li>
          <li>
            Object to automated decision-making that has legal or significant effects on you.
          </li>
          <li>Withdraw consent to data processing at any time.</li>
          <li>File a complaint with the UAE Data Protection Office.</li>
        </ul>
        <p>
          If you want to exercise these rights, please contact our support team via the provided contact details.
        </p>
        <p>
          <b>DATA SECURITY</b>
        </p>
        <p>
          We value your trust and take steps to prevent the loss, misuse, or disclosure of your data.
        </p>
        <p>
          <b>CHILDREN'S PRIVACY</b>
        </p>
        <p>
          This website is not intended for children, and we do not knowingly collect data about children. AMAIS services are not intended for individuals under 21 years old.
        </p>
        <p>
          <b>CHANGES TO THE PRIVACY POLICY</b>
        </p>
        <p>
          Please review this Privacy Policy periodically to stay informed of any changes. Let us know if your data changes during our relationship.
        </p>
        <p>
          <b>ADDITIONAL INFORMATION</b>
        </p>
        <p>
          If you have any questions about how we handle your data, please contact us at{" "}
          <a style={{ color: "black" }} href={`mailto:${mainData.email}`}>
            {mainData.email}
          </a>
          .
        </p>
      </TextWrapper>
    </>
  );
};

export default PrivacyPolicyEN;
