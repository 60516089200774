import React from "react";
import { useTranslation } from "react-i18next";
import TermsConditionsEN from "./TermsConditionsEN";
import TermsConditionsRU from "./TermsConditionsRU";
import ContactsSection from "../../components/ContactsSection";
import CallbackSection from "../../components/CallbackSection";

const TermsConditionsPage = () => {
  const { i18n } = useTranslation();
  const currentLanguageIsEN = i18n.language === 'en';

  return (
    <>
      {currentLanguageIsEN ? <TermsConditionsEN /> : <TermsConditionsRU />}
      <CallbackSection />
      <ContactsSection />
    </>
  );
};

export default TermsConditionsPage;
