import React from "react";
import { SectionTitle } from "../main";
import {
  BenefitsSectionContainer,
  StepContainer,
  StepDesc,
  StepIcon,
  StepLeft,
  StepRight,
  StepsContainer,
  StepTitle,
} from "./BenefitsSectionElements";
import { useTranslation } from "react-i18next";
import b1Icon from "../../images/benefits/b1.svg";
import b2Icon from "../../images/benefits/b2.svg";
import b3Icon from "../../images/benefits/b3.svg";
import b4Icon from "../../images/benefits/b4.svg";
import b5Icon from "../../images/benefits/b5.svg";
import b6Icon from "../../images/benefits/b6.svg";

const BenefitsSection = () => {
  const icons = [b1Icon, b2Icon, b3Icon, b4Icon, b5Icon, b6Icon];
  const { t } = useTranslation();
  const stepData = t("benefitsSection.stepData", { returnObjects: true }) ?? [];

  const stepDataWithIcons = stepData.map((item, index) => ({
    ...item,
    icon: icons[index]
  }));

  return (
    <BenefitsSectionContainer id="benefits">
      <SectionTitle>{t("benefitsSection.sectionTitle")}</SectionTitle>
      <StepsContainer>
        {stepDataWithIcons?.map((item, index) => (
          <StepContainer key={index}>
            <StepRight>
              <StepIcon src={item.icon} />
            </StepRight>
            <StepLeft>
              <StepTitle>{item.title}</StepTitle>
              <StepDesc>{item.desc}</StepDesc>
            </StepLeft>
          </StepContainer>
        ))}
      </StepsContainer>
    </BenefitsSectionContainer>
  );
};

export default BenefitsSection;
